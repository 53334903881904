import * as React from "react"

interface Props {
  size: number
}

export const RulerIcon: React.FunctionComponent<Props> = props => {
  const { size } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      fill="#fff"
    >
      <path d="M503.227 93.14L418.859 8.774c-11.699-11.699-30.734-11.699-42.433 0L258.598 126.602c0 .003 0 .003-.004.003l-87.98 87.98-.005.005L38.645 346.559c-.004 0-.004.003-.008.003l-.004.004-29.86 29.86c-11.699 11.699-11.699 30.734 0 42.433l84.368 84.368c5.851 5.847 13.53 8.773 21.214 8.773 7.684 0 15.368-2.926 21.22-8.773l45.566-45.567c3.906-3.906 3.906-10.238 0-14.144s-10.239-3.907-14.145 0l-45.566 45.566c-3.899 3.902-10.246 3.902-14.145 0l-84.367-84.367c-3.902-3.899-3.902-10.246 0-14.145l22.793-22.789 33.164 33.164a9.963 9.963 0 007.07 2.926 9.957 9.957 0 007.07-2.926c3.907-3.906 3.907-10.238 0-14.148l-33.163-33.164 29.847-29.848 11.707 11.707a9.968 9.968 0 007.07 2.93 9.976 9.976 0 007.075-2.93c3.902-3.906 3.902-10.238 0-14.144l-11.707-11.707 29.847-29.848 33.164 33.164a9.97 9.97 0 007.075 2.93 9.968 9.968 0 007.07-2.93c3.906-3.902 3.906-10.238 0-14.14l-33.164-33.169 29.848-29.847 11.707 11.707a9.968 9.968 0 007.07 2.93 9.961 9.961 0 007.07-2.93c3.907-3.906 3.907-10.238 0-14.145l-11.703-11.707 29.848-29.847 33.148 33.152a9.976 9.976 0 007.074 2.93 9.968 9.968 0 007.07-2.93c3.907-3.906 3.907-10.238 0-14.145l-33.152-33.152 29.848-29.848 11.707 11.707a9.968 9.968 0 007.07 2.93 9.97 9.97 0 007.075-2.93c3.906-3.906 3.906-10.238 0-14.144l-11.707-11.707 29.847-29.848 33.164 33.164a9.983 9.983 0 007.075 2.93 9.961 9.961 0 007.07-2.93c3.906-3.906 3.906-10.238 0-14.144L323.8 89.68l29.847-29.848 11.707 11.707a9.968 9.968 0 007.07 2.93 9.976 9.976 0 007.075-2.93c3.902-3.906 3.902-10.238 0-14.144L367.797 45.69l22.773-22.773c3.899-3.898 10.246-3.898 14.145 0l84.367 84.367c3.898 3.899 3.898 10.246 0 14.145L223.574 386.937c-3.906 3.907-3.906 10.239 0 14.145s10.239 3.906 14.145 0l265.508-265.508c11.699-11.699 11.699-30.734 0-42.433zm0 0" />
      <path d="M202.422 412.36c-2.64 0-5.219 1.07-7.078 2.93a10.055 10.055 0 00-2.922 7.07c0 2.632 1.058 5.21 2.922 7.07a10.06 10.06 0 007.078 2.93c2.633 0 5.21-1.067 7.07-2.93a10.06 10.06 0 002.934-7.07c0-2.63-1.07-5.212-2.934-7.07a10.073 10.073 0 00-7.07-2.93zm0 0" />
    </svg>
  )
}
